@import 'base/vars.scss';
@import 'base/tools.scss';
@import 'base/root.scss';
@import 'base/mixins.scss';

// @import "/home/bitrix/www/schoko.school.cdn/src/scss/global/_fonts.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/global/_global.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/global/_svg.scss";

// @import "/home/bitrix/www/schoko.school.cdn/src/scss/_local-fonts.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/base/_mixins.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/base/_root.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/base/_tools.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/base/_vars.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/global/_fonts.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/global/_global.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/scss/global/_svg.scss";

@import "/home/bitrix/www/schoko.school.cdn/src/components/_about.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_animations.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_form.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_map.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_mobile-menu.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_partner.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_program.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_reviews.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_stage.scss";
@import "/home/bitrix/www/schoko.school.cdn/src/components/_team.scss";




div[class*="uc-zw-"],
.hide-popup-zero {
	position: absolute;
	width: 100%;
	top: 0;
	opacity: 0;
	z-index: -10;
	pointer-events: none;
}

.uc-main-popup .t-popup {
	padding: 0;
	display: block !important;
	pointer-events: none;
}

.uc-main-popup .t-popup__container {
	left: 0;
	right: 0;
	background: none;
}

.tn-atom .t-form__errorbox-wrapper,
.tn-form__errorbox-popup,
.t-form-success-popup {
	z-index: 9999999 !important
}

div#tilda-popup-for-error {
	display: none !important
}

.popup-zero .t396__filter {
	cursor: pointer
}

.uc-main-popup .t-popup.t-popup_show {
	pointer-events: auto
}

.uc-main-popup .t-popup {
	transition: none
}

.uc-main-popup .popup-transition {
	transition: opacity ease-in-out .3s;
}

.uc-main-popup {
	position: fixed;
	z-index: 9999;
}

#rec586280415 .tn-elem[data-elem-id="1683100089938"] .tn-atom {
	background-color: transparent !important;
}

// Ссылки

.link {

	&--light a,
	--dark a {
		transition: object($transition, default);
	}

	&--light a:hover {
		opacity: 0.8 !important;
	}

	&--dark a:hover {
		opacity: 0.8 !important;
	}
}

// Логотив 
.footer-logo {
	transition: object($transition, default);

	&:hover {
		opacity: 0.8 !important;
	}
}


// Иконки соц сетей
.social-icons--vk,
.social-icons--tg,
.social-icons--ok {
	// transition: object($transition, default);
}

.social-icons--vk:hover {
	filter: brightness(0) saturate(100%) invert(29%) sepia(91%) saturate(2568%) hue-rotate(201deg) brightness(101%) contrast(108%);
}

.social-icons--ok:hover {
	filter: brightness(0) saturate(100%) invert(72%) sepia(28%) saturate(6554%) hue-rotate(341deg) brightness(96%) contrast(93%);
}

.social-icons--tg:hover {
	filter: brightness(0) saturate(100%) invert(54%) sepia(99%) saturate(1049%) hue-rotate(171deg) brightness(94%) contrast(100%);
}

// Якорьное смещение
[data-record-type="215"] {
	/* Укажи величину смещения для разрешения больше 1200px */
	top: -30px;
	position: relative;
}

@media (max-width: 1199px) {
	[data-record-type="215"] {
		/* Укажи величину смещения для разрешения 961-1200px */
		top: -30px;
		position: relative;
	}
}

@media (max-width: 959px) {
	[data-record-type="215"] {
		/* Укажи величину смещения для разрешения 641-960px */
		top: -90px;
		position: relative;
	}
}

@media (max-width: 639px) {
	[data-record-type="215"] {
		/* Укажи величину смещения для разрешения 481-640px */
		top: -55px;
		position: relative;
	}
}

@media (max-width: 479px) {
	[data-record-type="215"] {
		/* Укажи величину смещения для разрешения меньше 480px */
		top: -55px;
		position: relative;
	}
}


.bg-video {
	height: 100%;
}

#rec589565242 {
	height: 100%;

	& .t-cover__filter {
		height: 100% !important;
	}

	& .t-cover {
		height: 100% !important;
	}
}