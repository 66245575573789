.partner-icon {
	// transition: object($transition, default);

	// &:hover {
	// 	opacity: 0.8;
	// }
}


#rec585952689 {
	/* Добавьте высоту слайдов на разных разрешениях дисплея */

	/* xl */
	& .partner-slider .t-rec .t396__artboard {
		width: 100% !important;
		word-break: normal !important;
		background: transparent !important;
	}

	// /* lg */
	// @media screen and (max-width: 1199px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* md */
	// @media screen and (max-width: 959px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* sm */
	// @media screen and (max-width: 639px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 350px !important;
	// 	}
	// }

	/**/
	// @media screen and (max-width: 479px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 450px !important;
	// 	}
	// }

	& .partner-slider {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		align-content: stretch;
	}

	& .partner-slider .t-rec,
	.partner-slider .t396__artboard {
		overflow: hidden !important;
	}

	/* hide filters */
	& .partner-slider .t-rec .t396__artboard>.t396__carrier,
	.partner-slider .t-rec .t396__artboard>.t396__filter {
		display: none !important;
	}

}