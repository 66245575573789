.uc-content-program,
.uc-content-program-1,
#rec586872512 {
	display: none;
}

.ucx-program-imeges {
	overflow: hidden;
	border-radius: 10px;
}

.ucx-program-imeges,
.ucx-program-imeges .tn-atom,
.ucx-program-imeges img,
.content-layer-2 p {
	overflow: hidden;
	transition: all .3s ease-in-out !important;
	cursor: pointer;
}


.program-content {
	box-sizing: border-box;
	position: absolute;
	background: rgba(0, 0, 0, 0.15);
	width: 100%;
	height: 100%;
	display: block;
	left: 0;
	top: 0;
	transition: all .3s ease-in-out !important;
	padding: 30px 30px 40px 30px;
	z-index: 100;

	@include MOBILE {
		padding: 20px 10px 20px 10px;
	}

	&__wrap {
		position: absolute;
		top: 80%;
		transition: all .3s ease-in-out !important;

		& .t-card {
			&__title {
				padding-bottom: 25px;

				@include MOBILE {
					padding-bottom: 20px;
				}
			}

			&__descr {
				max-width: 80%;
				padding-bottom: 20px;

				@include MOBILE {
					// max-width: 70%;
					font-size: 16px !important;
				}
			}

			&__uptitle {
				position: relative;
				display: inline-block;
				padding-bottom: 40px;
				letter-spacing: normal;

				@include MOBILE {
					padding-bottom: 20px !important;
					font-size: 20px !important;
				}

				&::after {
					content: "";
					position: absolute;
					top: 2px;
					right: -24px;
					width: 24px;
					height: 24px;
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.6243 3.25H7.58268V13H5.41602V15.1667H7.58268V17.3333H5.41602V19.5H7.58268V22.75H9.74935V19.5H14.0827V17.3333H9.74935V15.1667H14.6243C17.9177 15.1667 20.5827 12.5017 20.5827 9.20833C20.5827 5.915 17.9177 3.25 14.6243 3.25ZM14.6243 13H9.74935V5.41667H14.6243C16.7152 5.41667 18.416 7.1175 18.416 9.20833C18.416 11.2992 16.7152 13 14.6243 13Z' fill='%23EB675B'/%3e%3c/svg%3e ");
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;

					@include MOBILE {
						width: 18px;
						height: 18px;
						right: -18px;
					}
				}


			}
		}
	}

	& .t-card__uptitle,
	.t-card__descr,
	.t-card__btn-wrapper {
		opacity: 0;
		transition: all .3s ease-in-out !important;
	}

	& .t-card__btn-wrapper {
		display: flex;
	}

	& .t142A__wrapone {
		justify-content: flex-start;

		& .t142A__wraptwo {
			display: flex;
			gap: 42px;
			padding: 0;

			& .t142A__btn {
				transition: object($transition, default);
				margin: 0;
				font-weight: 500;

				&:hover {
					transform: scale(1.05);
				}

			}

			& .t-btn--primary {
				&:hover {
					background-color: #613E49 !important;
				}
			}

			& .t-btn--second {
				&:hover {
					background-color: #FF8378 !important;
				}
			}

			& .t-btn_md {
				width: 240px;

				@include MOBILE {
					width: 125px;
					height: 40px !important;
				}
			}
		}
	}
}

.ucx-program-imeges:hover {

	& .program-content {
		background: rgba(0, 0, 0, 0.8);

		&__wrap {
			top: 20%;

			@include MOBILE {
				top: 5%;
			}
		}
	}

	& .t-card__uptitle,
	.t-card__descr,
	.t-card__btn-wrapper {
		opacity: 1;
	}


}