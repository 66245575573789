#rec586330783 {
	/* Добавьте высоту слайдов на разных разрешениях дисплея */

	/* xl */
	& .review-slider .t-rec .t396__artboard {
		width: 100% !important;
		// height: 350px !important;
		word-break: normal !important;
		background: transparent !important;
	}

	& .review-slider {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		align-content: stretch;
		border-radius: 40px;
		overflow: hidden;
	}

	& .review-slider .t-rec,
	.review-slider .t396__artboard {
		overflow: hidden !important;
	}

	/* hide filters */
	& .review-slider .t-rec .t396__artboard>.t396__carrier,
	.review-slider .t-rec .t396__artboard>.t396__filter {
		display: none !important;
	}

	& .owl-dot {

		& span {
			width: 20px !important;
			height: 20px !important;
			background-color: var(--color-bg-bg-01) !important;
			border-radius: 10em;
			display: inline-block;
			margin: 10px;
			transition: background-color .5s ease, transform .5s ease;
		}

		&.active {

			& span {
				background: var(--color-primary-default) !important;
				transform: scale(1.1)
			}

		}
	}
}

#rec590246039 {
	/* Добавьте высоту слайдов на разных разрешениях дисплея */

	/* xl */
	& .review-slider-mob .t-rec .t396__artboard {
		width: 100% !important;
		// height: 350px !important;
		word-break: normal !important;
		background: transparent !important;
	}

	& .review-slider-mob {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		align-content: stretch;
		border-radius: 40px;
		overflow: hidden;
	}

	& .review-slider-mob .t-rec,
	.review-slider-mob .t396__artboard {
		overflow: hidden !important;
	}

	/* hide filters */
	& .review-slider .t-rec .t396__artboard>.t396__carrier,
	.review-slider .t-rec .t396__artboard>.t396__filter {
		display: none !important;
	}

	& .owl-dot {

		& span {
			width: 20px !important;
			height: 20px !important;
			background-color: var(--color-bg-bg-01) !important;
			border-radius: 10em;
			display: inline-block;
			margin: 10px;
			transition: background-color .5s ease, transform .5s ease;
		}

		&.active {

			& span {
				background: var(--color-primary-default) !important;
				transform: scale(1.1)
			}

		}
	}
}