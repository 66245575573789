@function object($obj, $key) {
	@if map-has-key($obj, $key) {
		@return map-get($obj, $key);
	}

	@warn "Unknown `#{$key}` in $obj.";
	@return null;
}


@mixin MOBILE() {
	@media screen and (max-width:640px) {
		@content;
	}
}

@mixin DESKTOP() {
	@media screen and (max-width:960px) {
		@content;
	}
}

@mixin NOT-MOBILE() {
	@media screen and (min-width: 640px) {
		@content;
	}
}