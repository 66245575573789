:root {

	@each $color,
	$value in $colorText {
		--color-text-#{$color}: #{$value};
	}

	@each $color,
	$value in $colorPrimary {
		--color-primary-#{$color}: #{$value};
	}

	@each $color,
	$value in $colorSecondary {
		--color-secondary-#{$color}: #{$value};
	}

	@each $color,
	$value in $colorBG {
		--color-bg-#{$color}: #{$value};
	}



	@each $color,
	$value in $colorUI {
		--color-ui-#{$color}: #{$value};
	}


	@each $color,
	$value in $radius {
		--border-radius-#{$color}: #{$value};
	}


	--transitions: #{object($transition, default)};
	--transitions--transform: #{object($transition, transform)};
	--box-shadow: #{object($box-shadow, default)};
	--box-shadow--modal: #{object($box-shadow, modal)};

}