// Кнопка подробнее
.team-more {
	& .tn-atom {

		&::after {
			content: '';
			position: absolute;
			top: 2px;
			right: -30px;
			width: 24px;
			height: 24px;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.8742 21.0999L17.2992 12.6999C17.3992 12.5999 17.4702 12.4916 17.5122 12.3749C17.5542 12.2582 17.5749 12.1332 17.5742 11.9999C17.5742 11.8666 17.5535 11.7416 17.5122 11.6249C17.4709 11.5082 17.3999 11.3999 17.2992 11.2999L8.8742 2.8749C8.64087 2.64157 8.3492 2.5249 7.9992 2.5249C7.6492 2.5249 7.3492 2.6499 7.0992 2.8999C6.8492 3.1499 6.7242 3.44157 6.7242 3.7749C6.7242 4.10824 6.8492 4.3999 7.0992 4.6499L14.4492 11.9999L7.0992 19.3499C6.86587 19.5832 6.7492 19.8709 6.7492 20.2129C6.7492 20.5549 6.8742 20.8506 7.1242 21.0999C7.3742 21.3499 7.66587 21.4749 7.9992 21.4749C8.33254 21.4749 8.6242 21.3499 8.8742 21.0999Z' fill='%23EB675B'/%3e%3c/svg%3e ");
			background-repeat: no-repeat;
			background-position: center;
			transition: object($transition, default);
		}
	}

	& .tn-atom:hover {
		color: var(--color-text-primary) !important;
		transition: object($transition, default);

		&::after {
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.8742 21.0999L17.2992 12.6999C17.3992 12.5999 17.4702 12.4916 17.5122 12.3749C17.5542 12.2582 17.5749 12.1332 17.5742 11.9999C17.5742 11.8666 17.5535 11.7416 17.5122 11.6249C17.4709 11.5082 17.3999 11.3999 17.2992 11.2999L8.8742 2.8749C8.64087 2.64157 8.3492 2.5249 7.9992 2.5249C7.6492 2.5249 7.3492 2.6499 7.0992 2.8999C6.8492 3.1499 6.7242 3.44157 6.7242 3.7749C6.7242 4.10824 6.8492 4.3999 7.0992 4.6499L14.4492 11.9999L7.0992 19.3499C6.86587 19.5832 6.7492 19.8709 6.7492 20.2129C6.7492 20.5549 6.8742 20.8506 7.1242 21.0999C7.3742 21.3499 7.66587 21.4749 7.9992 21.4749C8.33254 21.4749 8.6242 21.3499 8.8742 21.0999Z' fill='%2339232A'/%3e%3c/svg%3e ");
		}
	}
}


#rec589060963 {
	/* Добавьте высоту слайдов на разных разрешениях дисплея */

	/* xl */
	& .team-slider-1 .t-rec .t396__artboard {
		width: 100% !important;
		word-break: normal !important;
		background: transparent !important;
	}

	// /* lg */
	// @media screen and (max-width: 1199px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* md */
	// @media screen and (max-width: 959px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* sm */
	// @media screen and (max-width: 639px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 350px !important;
	// 	}
	// }

	/**/
	// @media screen and (max-width: 479px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 450px !important;
	// 	}
	// }

	& .team-slider-1 {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		align-content: stretch;
	}

	& .team-slider-1 .t-rec,
	.team-slider-1 .t396__artboard {
		overflow: hidden !important;
	}

	/* hide filters */
	& .team-slider-1 .t-rec .t396__artboard>.t396__carrier,
	.team-slider-1 .t-rec .t396__artboard>.t396__filter {
		display: none !important;
	}

}

#rec589112323 {
	/* Добавьте высоту слайдов на разных разрешениях дисплея */

	/* xl */
	& .team-slider-2 .t-rec .t396__artboard {
		width: 100% !important;
		word-break: normal !important;
		background: transparent !important;
	}

	// /* lg */
	// @media screen and (max-width: 1199px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* md */
	// @media screen and (max-width: 959px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* sm */
	// @media screen and (max-width: 639px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 350px !important;
	// 	}
	// }

	/**/
	// @media screen and (max-width: 479px) {
	// 	.team-slider-1 .t-rec .t396__artboard {
	// 		height: 450px !important;
	// 	}
	// }

	& .team-slider-2 {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		align-content: stretch;
	}

	& .team-slider-2 .t-rec,
	.team-slider-2 .t396__artboard {
		overflow: hidden !important;
	}

	/* hide filters */
	& .team-slider-2 .t-rec .t396__artboard>.t396__carrier,
	.team-slider-2 .t-rec .t396__artboard>.t396__filter {
		display: none !important;
	}

}

#rec589060963 .tn-elem[data-elem-id="1683712726987"] .tn-atom {
	background-color: transparent !important;
}

#rec589112323 .tn-elem[data-elem-id="1683712726987"] .tn-atom {
	background-color: transparent !important;
}

#rec589113760 .tn-elem[data-elem-id="1683712840466"] .tn-atom,
#rec589113904 .tn-elem[data-elem-id="1683712840466"] .tn-atom,
#rec589062963 .tn-elem[data-elem-id="1683712840466"] .tn-atom,
#rec589062963 .tn-elem[data-elem-id="1683712840466"] .tn-atom,
#rec589076826 .tn-elem[data-elem-id="1683712840466"] .tn-atom,
#rec589109009 .tn-elem[data-elem-id="1683712840466"] .tn-atom {
	background-color: #eb675b !important;
}