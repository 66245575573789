 /*Настройки*/
 :root {
 	/*Ширина меню при разных разрешениях*/
 	--w: 760px;
 	/*Ширина меню на десктопе*/
 	--w1200: 560px;
 	/*Ширина меню при разрешении 1200px*/
 	--w960: 460px;
 	/*Ширина меню при разрешении 960px*/
 	--w640: 360px;
 	/*Ширина меню при разрешении 640px*/
 	--w480: 100%;
 	/*Ширина меню при разрешении 480px*/

 	/*Настройки слоя затемнения*/
 	--owerlay-bg: #000;
 	/*Цвет слоя затемнения*/
 	--owerlay-opacity: .5;
 	/*Непрозрачность слоя затемнения (0 целых, 8 десятых или 80%)*/

 	/*Скорость анимации появления меню*/
 	--anim-nav: 300ms;
 	/*в ms (1000ms = 1сек)*/
 }

 /*Ниже ничего не меняем*/
 .anku-nav-overlay {
 	display: block;
 	position: fixed;
 	width: 100%;
 	left: 0;
 	right: 0;
 	top: 0;
 	bottom: 0;
 	opacity: 0;
 	pointer-events: none;
 	z-index: 9200;
 	background: var(--owerlay-bg);
 	transition: opacity var(--anim-nav) ease
 }

 .anku-nav-wrapper {
 	display: block;
 	position: fixed;
 	width: var(--w);
 	left: calc(0px - var(--w));
 	top: 0;
 	bottom: 0;
 	z-index: 9300;
 	background: #fff;
 	transition: all var(--anim-nav) ease !important
 }

 .zero-nav-layer {
 	width: var(--w)
 }

 .anku-nav-overlay {
 	cursor: pointer
 }

 .anku-nav-is-open {
 	overflow: hidden !important
 }

 .anku-nav-is-open .anku-nav-overlay {
 	opacity: var(--owerlay-opacity);
 	pointer-events: all
 }

 .anku-nav-is-open .anku-nav-wrapper {
 	transform: translateX(var(--w))
 }

 @media screen and (max-width:1200px) {
 	.zero-nav-layer {
 		width: var(--w1200)
 	}

 	.anku-nav-wrapper {
 		width: var(--w1200);
 		left: calc(0px - var(--w1200))
 	}

 	.anku-nav-is-open .anku-nav-wrapper {
 		transform: translateX(var(--w1200))
 	}
 }

 @media screen and (max-width:960px) {
 	.zero-nav-layer {
 		width: var(--w960)
 	}

 	.anku-nav-wrapper {
 		width: var(--w960);
 		left: calc(0px - var(--w960))
 	}

 	.anku-nav-is-open .anku-nav-wrapper {
 		transform: translateX(var(--w960))
 	}
 }

 @media screen and (max-width:640px) {
 	.zero-nav-layer {
 		width: var(--w640)
 	}

 	.anku-nav-wrapper {
 		width: var(--w640);
 		right: calc(var(--w640))
 	}

 	.anku-nav-is-open .anku-nav-wrapper {
 		transform: translateX(0px - var(--w640))
 	}
 }

 @media screen and (max-width:480px) {
 	.zero-nav-layer {
 		width: var(--w480)
 	}

 	.anku-nav-wrapper {
 		width: var(--w480);
 		right: calc(var(--w480))
 	}

 	.anku-nav-is-open .anku-nav-wrapper {
 		transform: translateX(0px - var(--w480))
 	}
 }