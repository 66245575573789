.ucx-about-imeges {
	overflow: hidden;
	border-radius: 20px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 46%, rgba(0, 0, 0, 0) 146.92%);
		z-index: 1;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
		z-index: 2;
	}
}

#rec586280415 {
	/* Добавьте высоту слайдов на разных разрешениях дисплея */

	/* xl */
	& .about-slider .t-rec .t396__artboard {
		width: 100% !important;
		// height: 350px !important;
		word-break: normal !important;
		background: transparent !important;
	}

	// /* lg */
	// @media screen and (max-width: 1199px) {
	// 	.about-slider .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* md */
	// @media screen and (max-width: 959px) {
	// 	.about-slider .t-rec .t396__artboard {
	// 		height: 310px !important;
	// 	}
	// }

	// /* sm */
	// @media screen and (max-width: 639px) {
	// 	.about-slider .t-rec .t396__artboard {
	// 		height: 350px !important;
	// 	}
	// }

	/**/
	// @media screen and (max-width: 479px) {
	// 	.about-slider .t-rec .t396__artboard {
	// 		height: 450px !important;
	// 	}
	// }

	& .about-slider {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		align-content: stretch;
	}

	& .about-slider .t-rec,
	.about-slider .t396__artboard {
		overflow: hidden !important;
	}

	/* hide filters */
	& .about-slider .t-rec .t396__artboard>.t396__carrier,
	.about-slider .t-rec .t396__artboard>.t396__filter {
		display: none !important;
	}

	& .owl-dot {

		& span {
			width: 20px !important;
			height: 20px !important;
			background-color: var(--color-bg-bg-01) !important;
			border-radius: 10em;
			display: inline-block;
			margin: 10px;
			transition: background-color .5s ease, transform .5s ease;
		}

		&.active {

			& span {
				background: var(--color-primary-default) !important;
				transform: scale(1.1)
			}

		}
	}
}


.arrow-sliders {
	filter: brightness(0) invert(1);
	transition: object($transition, default);

	&:hover {
		filter: none;
		transform: scale(1.2);
	}
}


.tn-atom[href^="#popup-close"] {
	// transition: object($transition, default);

	&:hover {
		filter: brightness(0) saturate(100%) invert(59%) sepia(19%) saturate(6266%) hue-rotate(325deg) brightness(100%) contrast(85%);
		transform: scale(1.1);
	}

}